<template>
  <ul class="menu-nav">
    <!-- Home -->
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/home.png"
              alt="Home"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/home-red.png"
              alt="Home"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Home</span>
        </a>
      </li>
    </router-link>
    <!-- Log -->
    <!-- <router-link
      v-if="region"
      to="/log"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/home.png"
              alt="Home"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/home-red.png"
              alt="Home"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Log</span>
        </a>
      </li>
    </router-link> -->
    <!-- order history -->
    <router-link
      to="/order-history-details"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/order-history.png"
              alt="Order History"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/order-history-red.png"
              alt="Order History"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Order History & Details</span>
        </a>
      </li>
    </router-link>
    <!-- business lines -->
    <router-link
      to="/business-lines"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/business-lines.png"
              alt="Business Lines"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/business-lines-red.png"
              alt="Business Lines"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Business Lines</span>
        </a>
      </li>
    </router-link>
    <!-- rider -->
    <router-link
      to="/riders"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/baskytor-card.png"
              alt="Baskytor"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/baskytor-card-red.png"
              alt="Rider"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Baskytors</span>
        </a>
      </li>
    </router-link>
    <!-- vendor -->
    <router-link
      to="/vendor"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/vendor.png"
              alt="Vendor"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/vendor-red.png"
              alt="Vendor"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Vendor</span>
        </a>
      </li>
    </router-link>
    <!-- All Products -->
    <router-link
      to="/all-prodcuts"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/all-products.png"
              alt="All Products"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/all-products-red.png"
              alt="All Products"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">All Products</span>
        </a>
      </li>
    </router-link>
    <!-- All Categories -->
    <router-link
      to="/categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/cat-white.png"
              alt="All Products"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/cat-red.png"
              alt="All Products"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Categories</span>
        </a>
      </li>
    </router-link>
    <!-- All Users -->
    <router-link
      to="/users"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/users-white.png"
              alt="All Products"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/users-red.png"
              alt="All Products"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Users</span>
        </a>
      </li>
    </router-link>

    <!-- promo code and promotions -->
    <router-link
      to="/promo-code-promotions"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/promo-code.png"
              alt="Promo Code and Promotions"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/promo-code-red.png"
              alt="Promo Code and Promotions"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Promo Codes & Promotions</span>
        </a>
      </li>
    </router-link>
    <!-- Card Payments -->
    <router-link
      to="/payments"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/card-payment.png"
              alt="Card payments"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/card-payment-red.png"
              alt="Card payments"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Payments</span>
        </a>
      </li>
    </router-link>
    <!-- Notifications -->
    <router-link
      to="/notifications"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/notifications.png"
              alt="Notifications"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/notifications-red.png"
              alt="Notifications"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Notifications</span>
        </a>
      </li>
    </router-link>
    <!-- level bonuses -->
    <router-link
      to="/level-bonuses"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/level-bonus.png"
              alt="level and Bonuses"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/level-bonus-red.png"
              alt="level and Bonuses"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Levels & Bonuses</span>
        </a>
      </li>
    </router-link>
    <!-- Settings -->
    <router-link
      to="/settings"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/settings-white.png"
              alt="Business Lines"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/settings.png"
              alt="Business Lines"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Settings</span>
        </a>
      </li>
    </router-link>
    <!-- Admins -->
    <router-link
      to="/admins"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/adminpanel.png"
              alt="Business Lines"
              v-if="!isActive && !isExactActive"
            />
            <img
              src="/media/dashboard/menu-icons/adminpanel.png"
              alt="Business Lines"
              v-if="isActive && isExactActive"
            />
          </div>
          <span class="menu-text">Admins</span>
        </a>
      </li>
    </router-link>
    <!-- Help Center -->
    <router-link
      to="/help-center/customer"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon-img">
            <img
              src="/media/dashboard/menu-icons/help-center.png"
              alt="Help Center"
              v-if="!isActive"
            />
            <img
              src="/media/dashboard/menu-icons/help-center-red.png"
              alt="Help Center"
              v-if="isActive"
            />
          </div>
          <span class="menu-text"
            >Help Center
            <span
              v-if="messageNotification"
              class="menu-text--notification"
            ></span>
          </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import fire from "@/core/plugins/firestore";
import JwtService from "@/core/services/jwt.service";
export default {
  name: "KTMenu",
  data() {
    return {
      messageNotification: false,
      roomCollection: ""
    };
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  },
  async mounted() {
    let userId = JwtService.getUserId();
    if (this.region === "US") {
      if (this.stage === "PROD") {
        this.roomCollection = "rooms_us_prod";
      } else {
        this.roomCollection = "rooms_us_qa";
      }
    } else if (this.region === "PAKISTAN") {
      if (this.stage === "PROD") {
        this.roomCollection = "rooms_pk_prod";
      } else {
        this.roomCollection = "rooms_pk_qa";
      }
    }
    let email = "baskytrider440@gmail.com";
    let password = "baskyt112233";
    await fire.auth().signInWithEmailAndPassword(email, password);
    await fire
      .database()
      .ref(`${this.roomCollection}`)
      .orderByChild("is_seen")
      .on("value", snapshot => {
        this.messageNotification = false;
        let userData = snapshot.val();
        Object.keys(userData).forEach(key => {
          if (
            userData[key].is_seen === false &&
            userData[key].lastSender !== userId
          )
            this.messageNotification = true;
        });
      });
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
<style lang="scss">
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: #dc1828 !important;
  &--notification {
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
    margin-left: 15px;
  }
}
.aside-menu {
  .menu-nav {
    .menu-item {
      .menu-icon-img {
        height: 35px;
        width: 45px;

        img {
          height: auto;
          width: 25px;
        }
      }
    }
  }
}
</style>
